// extracted by mini-css-extract-plugin
export var background_images = "banner-module__background_images___+l29t";
export var banner = "banner-module__banner___dBUQX";
export var banner__avatar = "banner-module__banner__avatar___vuhEU";
export var banner__btn = "banner-module__banner__btn___Cl37A";
export var banner__container = "banner-module__banner__container___EmUle";
export var banner__content_btn = "banner-module__banner__content_btn___v3opK";
export var banner__subtitle = "banner-module__banner__subtitle___A-6yu";
export var banner__title = "banner-module__banner__title___2HseY";
export var body_background = "#f8f9fa";
export var card = "banner-module__card___XNK9W";
export var chip = "banner-module__chip___-zW5H";
export var container = "banner-module__container___PdlLK";
export var content = "banner-module__content___j72iz";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "banner-module__full_img___jmCOr";
export var full_imgSmall = "banner-module__full_img--small___2+Qhy";
export var gray_21 = "banner-module__gray_21___JaAZ4";
export var image_container = "banner-module__image_container___ggV09";
export var image_item = "banner-module__image_item___U1548";
export var lg = "1200px";
export var logo = "banner-module__logo___DsbHt";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "banner-module__primary___NmHW6";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "banner-module__title___ElCJ8";
export var typography_h1 = "banner-module__typography_h1___xitGI";
export var typography_h2 = "banner-module__typography_h2___kUEC3";
export var typography_h3 = "banner-module__typography_h3___RSNFt";
export var white = "#fff";
export var white_button = "banner-module__white_button___x08xs";
export var xl = "1536px";
export var xxl = "2500px";