import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Stack } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '@components';
import Data from '../../../utils/presentationCard.json';

import * as styles from './banner.module.scss';

const Banner = ({ intl: { formatMessage } }) => {
  return (
    <div className={styles.banner}>
      <Stack alignItems="center" spacing={1}>
        <div className={styles.banner__container}>
          <div className={styles.banner__avatar}>
            <StaticImage
              className={styles.banner__avatar__img}
              src="../../../images/presentationCard_avatar.webp"
              alt="avatar"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
            />
          </div>
        </div>
        <Typography variant="h5" className={styles.banner__title}>
          {Data.fullname}
        </Typography>
        <Typography variant="subtitle1" className={styles.banner__subtitle}>
          {formatMessage({ id: Data.title_key })}
        </Typography>
        <Button style={styles.banner__btn}>
          <a
            className={styles.banner__content_btn}
            href={Data.save_contact_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../../images/user-add.webp"
              alt="user-add"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
              width={20}
              height={20}
            />
            <span className="btn_text">
              {formatMessage({ id: 'addToContacts' })}
            </span>
          </a>
        </Button>
      </Stack>
    </div>
  );
};

Banner.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Banner);
